export default class AppConfig {

    static get GCP_PROJECT_ID() {
        return "kujakuja-nrc-dev";
    }

    static get API_URL() {
        return "https://api.dev.nrc.kujakuja.com/api/v3/";
    }

    static get LOGIN_REQUIRED() {
        return true;
    }

    static get DEFAULT_LOGIN_EMAIL() {
        return "website@kujakuja.com";
    }

    static get DEFAULT_LOGIN_PASSWORD() {
        return "website_pass";
    }

    static get SITE_HEADER_DEFAULT_HIGHLIGHT_COLOUR() {
        return "#FFC300";
    }

    static get SITE_HEADER_DEFAULT_TITLE_TEXT() {
        return "Customer feedback";
    }

    static get GOOGLE_TRANSLATE_API_KEY() {
        return "AIzaSyBWWcBJRcHXFrZfX40bV4soez4sXah4Fro";
    }

    static get TOP_KEYWORDS_ENABLED() {
        return false;
    }

    static get TOP_KEYWORDS_LOGIN_URL() {
        return "https://kujakuja.co/login";
    }

    static get TOP_KEYWORDS_LOGIN_USERNAME() {
        return "";
    }

    static get TOP_KEYWORDS_LOGIN_PASSWORD() {
        return "";
    }

    static get TOP_KEYWORDS_API_URL() {
        return "";
    }

    static get DATA_STUDIO_GRAPH_URL() {
        return "https://datastudio.google.com/embed/reporting/4d2fb70d-61be-4038-8977-1b3cf24d56bd/page/yipdB";
    }
}
